<template>
    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px); min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
                <a-row class="yj-form">
                    <a-col :span="24">
                        <label class="yj-form_label">姓名 :</label>
                        <a-input id="name"
                                 contextmenu="2455"
                                 :value="name"
                                 style="width:260px"
                                 @change="name_change"
                                 placeholder="姓名"></a-input>
                        <a-button type="primary" @click="search">查询</a-button>
                    </a-col>
                </a-row>
                <a-table :columns="columns" bordered
                         rowKey="ID"
                         :data-source="tableData"
                         :pagination="pagination"
                         @change="pagerChange"
                         style=" min-height: calc(100vh - 234px);min-width: max-content"
                         class="yj-table-img">
                    <span slot="name" slot-scope="text, record">
                        <a @click="jump(record)" style="cursor:pointer;"><img :src="record.UHeadImg" width="50" height="50" style="float: left;margin-right: 16px;" /><strong class='goods-name' style="line-height:50px;">{{record.Name }}</strong></a>
                    </span>
                    <span slot="action" slot-scope="text, record">
                        <a @click="Detail(record)">详情</a>
                    </span>
                </a-table>
            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "ChatWebInfor_List",
        data() {
            return {
                columns: [
                    {
                        title: "客户",
                        width: 230,
                        scopedSlots: { customRender: 'name' }
                    },
                    {
                        title: "电话",
                        dataIndex: "Phone",
                        align: "center",
                        width: 230
                    },
                    {
                        title: "对话数量",
                        dataIndex: "DialogCount",
                        align: "center",
                        width: 230
                    },
                    {
                        title: "未读数量",
                        align: "center",
                        width: 230,
                        customRender: (text, item) => {
                            var ServiceNotReadCount = item.ServiceNotReadCount;
                            if (ServiceNotReadCount > 0) {
                                return <div><a-badge count={ServiceNotReadCount} /> </div>;
                            } else {
                                return ServiceNotReadCount
                            }
                        }
                    },
                    {
                        title: "首次访问",
                        align: "center",
                        width: 230,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.CreateTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "最近访问",
                        align: "center",
                        width: 230,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.UpdateTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: '操作',
                        align: "center",
                        width: 230,
                        scopedSlots: { customRender: 'action' }
                    }
                ],
                tableData: [],
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                }
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/OperationsModule/Operations/GetChatWebInforList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.getTableData();
            },
            jump: function (data) {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail", query: {
                        ID: data.UserID
                    }
                })
            },
            Detail: function (data) {
                var self = this;
                self.$router.push({
                    name: "ChatWebInfor_Detail", query: {
                        ID: data.UserID
                    }
                })
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>